import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { IDefect, IDeviation, IMedCard, IMedicalReport, INorm, IdentCard, MedCardStatuses, MedCardTypes } from "./types";







interface Initial {
  card: IMedCard | null,
  identCard: IdentCard | null,
  norms: INorm[] | null,
  deviations: IDeviation[] | null,
  defects: IDefect[] | null,
  currentCardTimeProgress:number,
  base64FileString?:string,
  medicalReport?: IMedicalReport,
}

const initialState: Initial = {
  card: null,
  identCard: null,
  norms: null,
  deviations: null,
  defects:null,
  currentCardTimeProgress:0,
  base64FileString:undefined,
  medicalReport:undefined
};




export const examsSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setMedCardInfo(state, action: PayloadAction<IMedCard | null>) {
      state.card = action.payload;
    },
    setIdentCardInfo(state, action: PayloadAction<IdentCard | null>) {
      state.identCard = action.payload;
    },
    setNorms(state, action: PayloadAction<INorm[]>) {
      state.norms = action.payload;
    },
    setDeviations(state, action: PayloadAction<IDeviation[]>) {
      state.deviations = action.payload;
    },
    setDefects(state, action: PayloadAction<IDefect[]>) {
      state.defects = action.payload;
    },
    setBase64File(state, action: PayloadAction<string | undefined>){
        state.base64FileString = action.payload
    },
    setCardTimeProgress(state, action: PayloadAction<number>){
      state.currentCardTimeProgress = action.payload
    },
    setMedicalReport(state, action: PayloadAction<IMedicalReport>){
      state.medicalReport = action.payload
    }
  },
})

export const {
  setMedCardInfo,
  setIdentCardInfo,
  setNorms,
  setDeviations,
  setDefects,
  setCardTimeProgress,
  setBase64File,
  setMedicalReport
} = examsSlice.actions

export default examsSlice.reducer