import { useFetcher, useParams } from "react-router-dom";
import { getCardsRequest } from "../../services/DriverCardService"
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { useEffect, useState } from "react";
import { setReports } from "./ReportsSlice";
import { IMedCard, IReportStatus, MedCardStatuses } from "../Meds/types";
import { excelArgs, getExcelReportTableRequest } from "../../services/fileService";
import { downloadFile } from "./drawers/ReportCard";
import { Chip, Stack } from "@mui/material";
import { ConclusionFilter } from "./components/ConclusionFilter";
import { DateFilter } from "./components/DateFilter";
import { TimeFilter } from "./components/TimeFilter";
import { ComplaintsFilter } from "./components/ComplaintsFilter";
import { PressureFilter } from "./components/PressureFilter";
import { PulseFilter } from "./components/PulseFilter";
import { AlcoholFilter } from "./components/AlcoholFilter";
import { TemperatureFilter } from "./components/TemperatureFilter";
import { InspectTypeFilter } from "./components/InspectTypeFilter";
import { EDSFilter } from "./components/EDSFilter";
import { MessageIcon } from "./assets/MessageIcon";

export interface column {
    id: number,
    name?: string,
    sort?: any | false,
    sortMode?: string,
    filter?: any | false,
    value?: any,
    visible?: boolean,
}

export const getStatusChip = (status: IReportStatus, share?: boolean) => {

    const chipStyle = {
      '&.MuiChip-root': { 
        pointerEvents: 'none',
        maxHeight: '22px', border: '1px solid', borderRadius: '4px',
        fontFamily: 'Roboto', fintWeight: '400', fontSize: '14px', lineHeight: '18px',
        inlineSize: 'fit-content',
      },
      '& .MuiChip-label': { 
        padding: '2px 8px',
        marginTop: 'auto' 
      }
    }
  
    return (
      <Stack gap={'4px'}>
        {(()=>{
          switch (status) {
            case IReportStatus.ACCEPTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#EFFFEE', borderColor: '#33C341', color: '#33C341' }} variant="outlined" label={'Допущен'} />
            case IReportStatus.DEFECTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#FFF0DD', borderColor: '#F0901E', color: '#F0901E' }} variant="outlined" label={'Брак'} />
            case IReportStatus.REJECTED:
              return <Chip sx={{ ...chipStyle, backgroundColor: '#FFEAEA', borderColor: '#FF4D4F', color: '#FF4D4F' }} variant="outlined" label={'Не допущен'} />
            default:
              return <></> //<Chip sx={{ borderRadius: '4px' }} color="default" variant="outlined" label={status} />
          }
        })()}
        { share ? 
          <Chip sx={{ ...chipStyle, backgroundColor: '#EAF8FF', borderColor: '#009FDA', color: '#009FDA' }} variant="outlined" label={<MessageIcon />} /> 
        : <></>}
      </Stack>
    )
  }
  
  export const getDriverHealthReportChip = (row: IMedCard) => {
    return (
      <Stack gap={'4px'}>
      {!!row.driver_health_reports
      ? row.driver_health_reports.length > 0
        ? row.driver_health_reports?.map(
          (elem: any) => {
            return <Chip 
              sx={{ 
                inlineSize: 'fit-content', maxHeight: '24px',
                backgroundColor: '#009FDA', color: '#FFFFFF',
                fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
                '& .MuiChip-label': { padding: '2px 8px' },
                borderRadius: '4px',
              }}
              label={elem.message}
            ></Chip>
          })
            : <Chip
                sx={{ 
                  inlineSize: 'fit-content', maxHeight: '24px',
                  backgroundColor: '#8E8E8E', color: '#FFFFFF',
                  fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px', lineHeight: '16px',
                  '& .MuiChip-label': { padding: '2px 8px' },
                  borderRadius: '4px',
                }}
                label={'Нет'}
              ></Chip>
          : <></>
        }
    </Stack>
    )
  }

export const useReportsPaageModel = () => {

    const dispatch = useAppDispatch();
    const { medic } = useAppSelector(state => state.mainReducer)
    const { reports } = useAppSelector(state => state.reportsReducer)
    const { id } = medic
    const { curPage } = useParams();
    const [page, setPage] = useState<number>(1)
    const [pageQty, setPageQty] = useState(0);


    const [filterRequest, setFilterRequest] = useState<Object>({})
    const [sortingRequest, setSortingRequest] = useState<Object>({orderBy: {id: 'desc'}})


    const getMedReports = async (query: any) => {
      try {
        const response = await getCardsRequest(query)
        console.log('MEDREPORTS')
        console.log(response)
        return response
      } catch (e) {
        console.log(e)
      }
    }

  
    useEffect(() => {
        console.log('curPage')
        console.log(curPage)
        console.log(Number(curPage))
        if (!!curPage) {
            setPage(Number(curPage))
         } else setPage(1);
    }, [curPage])

    useEffect(() => {
        console.log('page')
        console.log(page)
        const resp = getMedReports({page: page, ...filterRequest, ...sortingRequest})
        resp.then((result) => {
            console.log('zalupa')
            console.log(result)
            dispatch(setReports(result?.data.data))
            setPageQty(result?.data.last_page)  
        })
    }, [page, filterRequest, sortingRequest])

    



    const changePage=(num:any)=>{
        setPage(num)
    }

    // useEffect(() => {
    //     if (reports === null) {
    //         const resp = getMedReports()
    //         resp.then((result) => {
    //             console.log('zalupa')
    //             console.log(result)
    //             dispatch(setReports(result?.data.data))
    //         })
    //     }

    // }, [reports])

    const setSortMode = (id: number, mode: string) => {
      setColumns((prev) => prev.map((col) => col.id === id ? {...col, sortMode: mode} : {...col, sortMode: ''} ))
    }

    const [openViewSettings, setOpenViewSettings] = useState<boolean>(false)

    const [columns, setColumns] = useState<column[]>([
        { 
          id: 1,
          name: '№', 
          sort: (cc: number) => {setSortingRequest({orderBy: { id: cc%2===0 ? 'desc' : 'asc' } }); setSortMode(1, cc%2===0 ? 'desc' : 'asc')},
          sortMode: '',
          filter: false,
          value: (row: IMedCard) => (row.id),
          visible: true,
        },
        { 
          id: 2,
          name: 'Заключение', 
          sort: (cc: number) => {setSortingRequest({orderBy: { medical_report: { status: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(2, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<ConclusionFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.medical_report?.status ? getStatusChip(row.medical_report?.status, row.medical_report.share) : <></>),
          visible: true,
        },
        { 
          id: 3,
          name: 'Дата', 
          sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(3, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<DateFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row?.createdAt ? new Date(row.createdAt.substring(0, 11)).toLocaleDateString('ru-RU') : '-'),
          visible: true,
        },
        { 
          id: 4,
          name: 'Время', 
          sort: (cc: number) => {setSortingRequest({orderBy: { createdAt: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(4, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<TimeFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.createdAt?.substring(11)),
          visible: true,
        },
        { 
          id: 5,
          name: 'Наименование организации', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (row.driver?.company),
          visible: true,
        },
        { 
          id: 6,
          name: 'ФИО', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (`${row.driver?.lastname} ${row.driver?.firstname} ${row.driver?.middlename}`),
          visible: true,
        },
        { 
          id: 7,
          name: 'ФИО медработника', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (
            <span>
              {!!row.medic?.lastname ? `${row.medic?.lastname}` : <></>}
              {!!row.medic?.firstname ? `${row.medic?.firstname} ` : <></>}
              {!!row.medic?.middlename ? `${row.medic?.middlename} ` : <></>}
            </span>
          ),
          visible: true,
        },
        { 
          id: 8,
          name: '№ п/п', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (row.driver?.id),
          visible: true,
        },
        { 
          id: 9,
          name: 'Пол', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (row.driver?.gender),
          visible: true,
        },
        { 
          id: 10,
          name: 'Возраст', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (row.driver?.age),
          visible: true,
        },
        { 
          id: 11,
          name: 'Таб. номер', 
          sort: false,
          filter: false,
          value: (row: IMedCard) => (row.driver?.id),
          visible: true,
        },
        { 
          id: 12,
          name: 'Жалобы', 
          sort: (cc: number) => {setSortingRequest({orderBy: { driver_health_reports: { _count: cc%2===0 ? 'asc' : 'desc'} } }); setSortMode(12, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<ComplaintsFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (getDriverHealthReportChip(row)),
          visible: true,
        },
        { 
          id: 13,
          name: 'Артериальное давление', 
          sort: (cc: number) => {setSortingRequest({orderBy: cc%2===0 ? { diastolic_pressure: 'asc' } : { systolic_pressure: 'desc' } }); setSortMode(13, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<PressureFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.systolic_pressure + '/' + row.diastolic_pressure),
          visible: true,
        },
        { 
          id: 14,
          name: 'Пульс', 
          sort: (cc: number) => {setSortingRequest({orderBy: { pulse_rate: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(14, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<PulseFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.pulse_rate),
          visible: true,
        },
        { 
          id: 15,
          name: 'Содержание алкоголя', 
          sort: (cc: number) => {setSortingRequest({orderBy: { alcohol_content: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(15, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<AlcoholFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.alcohol_content),
          visible: true,
        },
        { 
          id: 16,
          name: 'Температура', 
          sort: (cc: number) => {setSortingRequest({orderBy: { temperature: cc%2===0 ? 'asc' : 'desc' } }); setSortMode(16, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<TemperatureFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),  
          value: (row: IMedCard) => (row.temperature),
          visible: true,
        },
        { 
          id: 17,
          name: 'Вид осмотра', 
          sort: (cc: number) => {setSortingRequest({orderBy: { type: { name: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(17, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<InspectTypeFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>), 
          value: (row: IMedCard) => (row.type),
          visible: true,
        },
        { 
          id: 18,
          name: 'ЭЦП медработника', 
          sort: (cc: number) => {setSortingRequest({orderBy: { certificate: { value: cc%2===0 ? 'asc' : 'desc' } } }); setSortMode(18, cc%2===0 ? 'asc' : 'desc')},
          sortMode: '',
          filter: (handleClose: any, setMode: any) => (<EDSFilter setFilterRequest={setFilterRequest} handleClose={handleClose} setMode={setMode}/>),
          value: (row: IMedCard) => (!!row.certificate ? row.certificate.value : ''),
          visible: true,
        },
    ])


    return {
        reports,
        page,
        pageQty,
        changePage,

        openViewSettings,
        setOpenViewSettings,
        columns,
        setColumns,
        setFilterRequest,

        filterRequest,
        sortingRequest,
    }
}