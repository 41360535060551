import { useState } from "react";
import { useForm } from "react-hook-form";
import { DeclineFormInputData, IMedCard, IdentCard } from "../../types";
import { updateCardRequest, updateDocumentRequest } from "../../../../services/DriverCardService";



export const useMedDrawerModel = (defaultValues: any) => {


    const [isOpen, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setOpen(open);
    };
    const {
        formState,
        getFieldState,
         handleSubmit,
          reset,
           control, setValue, register, getValues, setError, clearErrors, formState: { errors } } = useForm<any>({
        defaultValues: defaultValues,
    });

    const updateCard = async (data: IMedCard) => {
        try {
            const response = await updateCardRequest(data);
            console.log(response)
            const updatedData = response.data;
            return updatedData;
        } catch (e) {

        }
    }

    const updateIdentCard = async (data: IdentCard) => {
        try {
            const response = await updateDocumentRequest(data);
            console.log(response)
            const updatedData = response.data;
            return updatedData;
        } catch (e) {
            console.log(e)
        }
    }

    return {
        getFieldState,
        isOpen,
        toggleDrawer,
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        register,
        updateCard,
        updateIdentCard,
        errors,
        setError,
        clearErrors,
        formState
    }
}