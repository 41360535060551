import { useState } from "react";
import { DefectFormInputData, IDefect, IReportStatus, MedCardStatuses } from "../../../types";
import { useMedDrawerModel } from "../DrawerReportModel";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks/redux";
import { setBase64File, setMedCardInfo, setMedicalReport } from "../../../MedExamsSlice";
import { setConclusion } from "../../../MedCardPageModel";
import { Modal } from "@mui/material";
import { ModalNames } from "../../../../../slices/AppSlice";
import { useModal } from "../../../../../modules/layout/components/modals/useModal";



export const useDefectFormModel = (onClose: any) => {

    const [isTextFieldRequired, setTextFieldrequired] = useState<boolean>(false)

    const dispatch = useAppDispatch();
    const { card } = useAppSelector(state => state.examReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const { changeCurrentModal } = useModal();

    const options: IDefect[] = [
        {
            name: 'Невозможно идентифицировать работника',
            id: 1
        },
        {
            name: 'Прохождение осмотра за другого работника',
            id: 2
        },
        {
            name: 'Надет головоной убор, маска или темные очки',
            id: 3
        },
        {
            name: 'Недостаточно освещения',
            id: 4
        },
        {
            name: 'Фальсификация результатов измерений',
            id: 5
        },
        {
            name: 'Манжета надета на верхнюю одежду',
            id: 6
        },
        {
            name: 'Непристойный внешний вид или поведение',
            id: 7
        },
        {
            name: 'Другое (указать в заключении)',
            id: 8
        },
    ]

    const defaultValues: DefectFormInputData = {
        comment: '',
        checkboxes: [],
    }

    const templateModel = useMedDrawerModel(defaultValues);




    const onSubmit = async (data: DefectFormInputData) => {
        console.log(data)
        const isDataNotEmpty = data.checkboxes && data.checkboxes.length > 0;
        if (isDataNotEmpty) {
            console.log('Valid')
            const medicalReport = {
                defects: data.checkboxes,
                comment: !!data.comment ? data.comment : undefined,
                status: IReportStatus.DEFECTED,
            }
            dispatch(setMedicalReport(medicalReport))
            changeCurrentModal(ModalNames.CERTIFICATES)
            dispatch(setMedCardInfo(null))
            dispatch(setBase64File(undefined))
            onClose();
            // changeCurrentModal(ModalNames.ECP_SIGNING)
            // try {
            //     if(!!medic.id){
            //         const conclusionResult = await setConclusion(card, medicalReport, medic.id)
            //         if (!!conclusionResult) {
            //             changeCurrentModal(ModalNames.SUCCESS)
            //         } else {
            //             changeCurrentModal(ModalNames.ECP_ERROR)
            //         }
            //         console.log('CONCLUSION')
            //         console.log(conclusionResult)
            //         dispatch(setMedCardInfo(null))
            //         dispatch(setBase64File(undefined))
            //         onClose();
            //     }
                
            // } catch (e) {
            //     changeCurrentModal(ModalNames.ERROR)
            //     return
            // }


        } else console.log('Not Valid')
    };

    const submitHandler = templateModel.handleSubmit(onSubmit)

    return {
        control: templateModel.control,
        options,
        submitHandler,
        handleFormValues: templateModel.setValue,
        getValues: templateModel.getValues,
        register: templateModel.register,
        isTextFieldRequired,
    }
}