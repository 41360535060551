
import { FC, ReactNode, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classes from './layout.module.css'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavButton } from "./components/NavButton/NavButton";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Avatar, Badge, Button, Container, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { Location } from "../../modules/LocationDivider/Location";
import { Sidebar } from "../../modules/Sidebar/Sidebar";
import { Timer } from "../Timer/Timer";
import { useAppSelector } from "../../store/hooks/redux";
import { ModalContainer } from "../modalContainer/ModalContainer";
import { ModalDocumentNotFound } from "./components/modals/NotAddedDocsModal";
import { ModalDocumentError } from "./components/modals/ErrorDocsModal";
import { ExpiresDocsModal } from "./components/modals/ExpiresDocsModal";
//const drawerWidth = 256;


export const Layout = () => {


  const { isECPFlashLoaded } = useAppSelector(state => state.appReducer)

  const [drawerWidth, setDrawerWidth] = useState<number>(256)

  return (
    <Box sx={{
      display: 'flex',
      //  backgroundColor: 'blue',
      flexDirection: 'column'
    }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, color: 'black' }}
        aria-label="mailbox folders"

      >
        <Sidebar setWidth={setDrawerWidth}/>
      </Box>

      <div style={{ marginLeft: `${drawerWidth}px`, transition: 'all 0.3s ease-out' }}>
        <Box sx={{position: 'sticky', top: '0px', zIndex: '99'}}>
          <AppBar position="static"
            sx={{ width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: "row", justifyContent: 'flex-end', boxShadow: 'none', alignItems: 'center' }}
            >

            <Timer />

            <Toolbar sx={{ width: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {/* <Button onClick={ async () => {
                // openModal(ModalNames.ECP)
                console.log( await checkECPPrivateKey())
              }
              }>open modal</Button> */}
              <Badge badgeContent={4} color="secondary">
                <NotificationsNoneOutlinedIcon color="action" />
              </Badge>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar></Avatar>
                <Typography marginLeft={'10px'} color={'#3E3E3E'}>Тест Тестович</Typography>
              </div>
            </Toolbar>
          </AppBar>
          {/* <Location /> */}
        </Box>


        <Box
          component="main"
          sx={{ flexGrow: 1, width: '100%', backgroundColor: '#F0F2F5'}}
        >
          <Outlet />
        </Box>
      </div>
      {/* <ModalDocumentError open={true}/> */}
      {/* <ModalDocumentNotFound open={true}/> */}
      <ModalContainer></ModalContainer>
    </Box>
  );
}