import { Box, Fab, Stack } from "@mui/material"
import { TimerStatus } from "../Timer-types"
import { fabStyle, timerStyle } from "../styles"
import { CloseIcon } from "../assets/CloseIcon"
import { PauseIcon } from "../assets/PauseIcon"
import { PlayIcon } from "../assets/PlayIcon"
import { useEffect } from "react"
import { autoBatchEnhancer } from "@reduxjs/toolkit"
import Heart from "../assets/Heart.png"

export const PageOverlapTimer = (props: {
    open: boolean,
    status: TimerStatus,
    pauseClockFace: {h: string, m: string, s: string},
    handleSetTimer: () => void,
    handleFinishTimer: () => void,
    isDocumentsValid: boolean
}) => {

    return (
        <Box sx={{
            position: 'absolute',
            zIndex: '99',
            overflowX: 'hidden',

            height: '889px', //'916px', 
            backgroundColor: 'transparent',
            top: '64px',
            //left: '256px',
            left: '0px',
            //left: '-256px',
            right: '0px',

            display: 'flex',
            flexDirection: 'row',

            visibility: props.open ? 'visible' : 'hidden', // То что надо!
            transition: 'visibility 1s',
        }}>
            <Box sx={{                
                backgroundColor: 'white',
                width: '61.5%',
                display: 'flex',

                //marginLeft: props.open ? '0%' : '-100%',
                //transition: 'all 0.8s ease-out',

                position: 'absolute',
                height: '889px', //'916px', 
                left: '0px',

                transform: props.open ? 'none' : 'translate(200%, -10%)',
                opacity: props.open ? 1 : 0,
                transition: 'all 0.8s ease-out',
            }}>
                <Box sx={{
                    width: '459px',
                    height: '375px',
                    padding: '24px',
                    display: 'flex',
                    gap: '24px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: 'auto',
                }}>

                    <Box sx={{
                        ...timerStyle,
                        fontWeight: '700',
                        fontSize: '96px',
                        lineHeight: '112px',
                        textAlign: 'center',
                    }}>
                        <span>
                            {props.pauseClockFace.h}
                        </span>
                        <span>
                            :{props.pauseClockFace.m}
                        </span>
                        <span style={{color: '#8E8E8E'}}>
                            :{props.pauseClockFace.s}
                        </span>
                    </Box>

                    <Box
                        sx={{
                            width: '208px',
                            height: '88px',
                            display: 'flex',
                            gap: '86px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Fab
                            onClick={props.handleSetTimer}
                            disabled={
                                props.status === TimerStatus.OFFLINE
                                || !props.isDocumentsValid
                            }
                            sx={{
                                ...fabStyle,
                                backgroundColor: '#009FDA',
                                '&:hover': {backgroundColor: '#007FBA'},
                                scale: '2.7'
                            }}
                        >
                            {props.status !== TimerStatus.RUNNING ? 
                                <PlayIcon 
                                    sx={{
                                        //scale: '0.8', 
                                        scale: '0.8', 
                                        //width: '88px',
                                        //height: '88px',
                                        paddingLeft: '5px'
                                    }}
                                /> 
                                : 
                                <PauseIcon 
                                    sx={{
                                        //scale: '0.7'
                                        scale: '0.7'
                                        //width: '88px',
                                        //height: '88px',
                                    }}
                                />
                            }
                        </Fab>
                        <Fab 
                            onClick={props.handleFinishTimer}
                            disabled={
                                props.status === TimerStatus.FINISHED || 
                                props.status === TimerStatus.OFFLINE
                            }
                            sx={{...fabStyle,
                                backgroundColor: '#FF4D4F',
                                '&:hover': {backgroundColor: '#DD2D2F'},
                                scale: '2.7'
                            }}
                        >
                            <CloseIcon 
                                sx={{
                                    scale: '0.6',
                                    color: '#FFFFFF'
                                }} 
                            />
                        </Fab>
                    </Box>

                    <Box 
                        sx={{
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            fontSize: '20px',
                            lineHeight: '26px',
                            textAlign: 'left',
                            color: '#8E8E8E',

                            marginTop: '20px'
                        }}
                    >
                        Для начала или продолжения смены<br/>
                        нажмите «плей»<br/>
                        <br/>
                        Для завершения смены нажмите крестик
                    </Box>

                </Box>
            </Box>

            {/* <Box
                sx={{
                    backgroundColor: 'transparent',
                    width: props.open ? '0%' : '200%',
                    transition: 'all 0.8s ease-out',
                    pointerEvents: 'none',              // Не работает      
                }}
            /> */}

            <Box sx={{
                backgroundColor: '#009FDA',
                width: '38.5%',
                display: 'flex',

                //marginRight: props.open ? '0%' : '-100%',
                //transition: 'all 0.8s ease-out',

                position: 'absolute',
                height: '889px', //'916px', 
                right: '0px',

                transform: props.open ? 'none' : 'translate(200%, -10%)',
                opacity: props.open ? 1 : 0,
                transition: 'all 0.8s ease-out',
            }}>
                <Box
                    sx={{
                        width: '394px',
                        height: '352px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: 'auto',
                    }}
                >
                    <img src={Heart} alt="" width={'140px'} style={{ margin: 'auto' }} />
                    <Box
                        sx={{
                            fontFamily: 'Roboto',
                            fontWeight: '600',
                            fontSize: '48px',
                            lineHeight: '62px',
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >
                        {props.status === TimerStatus.PAUSED ? 'Ваша рабочая смена на паузе' : null}
                        {props.status === TimerStatus.FINISHED ? 'Ваша рабочая смена ещe не началась' : null}
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}