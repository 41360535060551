import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { textFieldOutlineMultilineStyle, textFieldOutlineStyle } from "./styles/styles";
import { useEffect, useState } from "react";

export const MedFormInputText = ({ name, control, label, rules, isDisabled }: any) => {




  useEffect(()=>{

  })
  const { required}= rules
  return (
    <Controller
    rules={rules}
      name={name}
      control={control ? control : null}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
        sx={ {...textFieldOutlineMultilineStyle,
           borderColor:required ? '#DC4C4C' : '#8E8E8E',
           backgroundColor:required ? '#FEEAEA' : 'white',
          } }
        multiline
          rows={4}
          // helperText={required ? 'Обязательно укажите причины' : null}
          size="small"
          error={!!required}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          disabled={isDisabled}
          InputLabelProps={{
            shrink: false,
            // sx:{
            //   '&.Mui-focused':{
            //     visibility:'hidden',
            //   }
            // }
          }}
        />
      )}
    />
  );
};
