import { useEffect, useState } from "react";
import { useReportsPaageModel } from "./ReportPageModel"
import { CustomTable } from "./CustomTable";
import { Box, Button, CircularProgress, Divider, Pagination, PaginationItem, Paper, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { activeColor, buttonStyle } from "../Meds/styles/styles";
import { GearIcon } from "../../modules/Sidebar/assets/GearIcon";
import { DownloadIcon } from "./assets/DownloadIcon";
import { MedCardStatuses } from "../Meds/types";
import { downloadFile } from "./drawers/ReportCard";
import { makeStyles } from '@mui/styles'
import { excelArgs, getExcelReportTableRequest } from "../../services/fileService";
import { ViewSettingsModal } from "./components/ViewSettingsModal";


export const ReportPage = () => {


    const { 
        reports, page, pageQty, changePage, 
        openViewSettings, setOpenViewSettings,
        columns, setColumns, 
        setFilterRequest,
        filterRequest, sortingRequest,
    } = useReportsPaageModel()

    const [exelLoading, setExelLoading] = useState<boolean>(false)

    const getExcelReportTable = async () => {
        try {
            setExelLoading(true)
            const response = await getExcelReportTableRequest({...filterRequest, ...sortingRequest})
            setExelLoading(false)
            console.log('excel')
            console.log(response)
            const localUrl = URL.createObjectURL(response.data)
            downloadFile(localUrl, 'table.xlsx')
        } catch (e) {

        }

        return {

        }
    }

    const useStyles: any = makeStyles((theme: any) => ({
        selected: {
            backgroundColor: 'blue',
            color: 'white',
        },
    }),
    );
    const classes = useStyles();

    return (
        <Box sx={{paddingBottom: '1px'}}>
            {/* <Divider sx={{color:'#EEEEEE'}}></Divider>
             <Box sx={{ backgroundColor: 'white', padding: '16px 24px' }}>
            <   Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Отчеты</Typography>
            </Box> */}

            <Box sx={{ backgroundColor: 'white', padding: '16px 24px', position: 'sticky', top: '65px', zIndex: '9', boxShadow: '0px -1px 0px 0px #EEEEEE'}}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: '500', lineHeight: '32px' }}>Отчеты</Typography>
            </Box>

            <Paper
                sx={{
                    margin:'17px 16px 15px 16px',
                    boxShadow: 'none',
                    padding: '16px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                }}
            >


                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: '20px', lineHeight: '23px' }}>
                        Медицинский журнал
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                        <Button
                            onClick={() => { getExcelReportTable(/*{ status: MedCardStatuses.FINISHED }*/) }}
                            variant="contained"
                            startIcon={exelLoading ? <CircularProgress sx={{color: '#FFFFFF'}} size={'20px'}/> : <DownloadIcon />}
                            sx={{
                                ...buttonStyle, backgroundColor: activeColor,
                                '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '23px',
                                padding: '8px 12px',
                            }}
                        >
                            Загрузить весь журнал
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                ...buttonStyle, backgroundColor: activeColor,
                                '&:hover': { backgroundColor: '#007DB8', boxShadow: 'none' },
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '23px',
                                padding: '8px 12px',
                                minWidth: '0px',
                            }}
                            onClick={() => setOpenViewSettings(!openViewSettings)}
                        >
                            <GearIcon />
                        </Button>
                    </Box>
                </Box>


                <CustomTable data={reports} columns={columns} setFilterRequest={setFilterRequest}></CustomTable>

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={pageQty}
                        page={page}
                        onChange={(_, num) => changePage(num)}
                        className={classes.root}
                        variant="outlined" shape="rounded"
                        sx={{
                            //marginY: 3, marginX: "auto",
                            '&  .Mui-selected': {
                                backgroundColor: '#009FDA !important',
                                color: 'white !important'
                            },
                            '& .Mui-selected:hover': {
                                backgroundColor: 'rgb(0,159,218) !important',
                                color: 'white !important'
                            },
                        }}
                        renderItem={(item) => (
                            <PaginationItem

                                {...item}

                                component={NavLink}
                                to={`/reports/${item.page}`}
                            // to={'/organisations'}

                            />
                        )}
                    />
                </Box>

                <ViewSettingsModal 
                    open={openViewSettings} 
                    setOpen={setOpenViewSettings} 
                    columns={columns}
                    setColumns={setColumns}
                />

            </Paper>
        </Box>
    )
}