import { useEffect, useState } from "react";
import { getDeviationsRequest } from "../../../../../services/DriverCardService";
import { DeclineFormInputData, ICheckbox, IDeviation, IMedCard, IMedicalReport, IReportStatus, MedCardStatuses } from "../../../types";
import { useMedDrawerModel } from "../DrawerReportModel"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks/redux";
import { setBase64File, setDeviations, setMedCardInfo, setMedicalReport } from "../../../MedExamsSlice";
import { createDetachedSignature, getCertificate, getUserCertificates, createHash } from 'crypto-pro';
import { createSign, getUserCertificate } from "../../../../../services/cryptoService";
import { createFileFromBase64, getBase64FileRequest } from "../../../../../services/fileService";
import { setConclusion } from "../../../MedCardPageModel";
import { useModal } from "../../../../../modules/layout/components/modals/useModal";
import { ModalNames } from "../../../../../slices/AppSlice";







export const useDeclineDrawerFormModel = (onClose: any) => {

    const defaultValues: DeclineFormInputData = {
        comment: '',
        checkboxes: [],
    };

    const dispatch = useAppDispatch();
    const { card, deviations, base64FileString } = useAppSelector(state => state.examReducer)
    const { medic } = useAppSelector(state => state.mainReducer)

    const [isDeviationsGetted, setDeviationsGetted] = useState<boolean>(false)
    const [options, setOptions] = useState<any>([])

    const templateModel = useMedDrawerModel(defaultValues);
    const { changeCurrentModal } = useModal();

    const [sMedic, setSMedic] = useState<boolean>(false)

    const getDeviationsToOptions = (deviations: IDeviation[] | null) => {
        const options = !!deviations ? deviations.map((element: IDeviation) => {

            if (element.id == 1 || element.id == 2) {
                return {
                    ...element,
                    declineType: 'pulse',
                }
            }
            else if (element.id == 3 || element.id == 4) {
                return {
                    ...element,
                    declineType: 'systolic pressure',
                }
            }
            else if (element.id == 5 || element.id == 6) {
                return {
                    ...element,
                    declineType: 'diastolic pressure',
                }
            }
            else {
                return element
            }

        }) : []
        console.log(options)
        return options;
    }

    useEffect(() => {
        if (card === null) onClose()
    }, [card])





    useEffect(() => {


        if (card !== null && !isDeviationsGetted) {
            !!card.id && getDeviations(card.id);
        }
    }, [card])


    useEffect(() => {
        setOptions(getDeviationsToOptions(deviations))
    }, [deviations])

    const getDeviations = async (id: number) => {
        try {
            const response = await getDeviationsRequest(id);
            console.log('DEVIATIONS')

            console.log(response)
            dispatch(setDeviations(response.data));
            setDeviationsGetted(true)
        } catch (e) {

        }
    }


    const checkDublicatesCheckboxes = (checkboxes: any) => {
        console.log('checkDuplicatesCheckboxes')
        console.log(checkboxes)
        const checked = checkboxes;
        let dublicates = [];
        for (let i = 0; i < checked.length; i++) {
            dublicates = checked.filter((element: any) => {
                return !!checked[i].declineType &&
                    !!element.declineType && (
                        checked[i].declineType === element.declineType
                        && checked[i].id !== element.id)
            })
            console.log('dublicates')
            if (dublicates.length > 0) return true
        }

        return false
    }



    const onSubmit = async (data: DeclineFormInputData) => {
        console.log('getValues')
        console.log(templateModel.getValues())
        const isDataNotEmpty = data.checkboxes && data.checkboxes.length > 0;
        console.log(data)
        const isHasNoDuplicates = checkDublicatesCheckboxes(data.checkboxes);
        console.log(isHasNoDuplicates)
        let medicalReport:IMedicalReport | null = null;
        if (isDataNotEmpty && !isHasNoDuplicates) {
            console.log('Valid')
            // if (data.checkboxes?.find((checkbox: any) => (checkbox.id === 11))) {
            //     data.checkboxes.splice(data.checkboxes.findIndex((checkbox: any) => (checkbox.id === 11)), 1)
            //     console.log('deleted')
            // }
            if(!!medic){
                if(!!medic.id){
                    medicalReport = {  
                        deviations: data.checkboxes?.map((element: ICheckbox) => {
                            return {
                                id: element.id,
                            }
                        }),
                        comment: !!data.comment ? data.comment : undefined,
                        status: IReportStatus.REJECTED,
                        medicId:medic.id,
                        share:sMedic
                    }
                    dispatch(setMedicalReport(medicalReport))
                    changeCurrentModal(ModalNames.CERTIFICATES)
                 
                    onClose();
                }
            }

            // changeCurrentModal(ModalNames.ECP_SIGNING)
            // try {

            //     if (!!medic) {
            //         if (medic.id !== null && !!medicalReport) {
            //             const conclusionResult = await setConclusion(card, medicalReport, medic.id)
            //             console.log('CONCLUSION')
            //             console.log(conclusionResult)

            //             if (!!conclusionResult) {
                            
            //                 changeCurrentModal(ModalNames.SUCCESS)
            //             } else {
            //                 changeCurrentModal(ModalNames.ECP_ERROR)
            //             }
            //             dispatch(setMedCardInfo(null))
            //             dispatch(setBase64File(undefined))
            //             onClose();
            //         }

            //     }

            // } catch (e) {
            //     return
            // }


        } else console.log('Not Valid')
    };

    const submitHandler = templateModel.handleSubmit(onSubmit)

    return {
        control: templateModel.control,
        onSubmit,
        submitHandler,
        handleFormValues: templateModel.setValue,
        register: templateModel.register,
        getValues: templateModel.getValues,
        setValue: templateModel.setValue,
        options,
        sMedic, setSMedic
    }
}