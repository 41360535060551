import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseLocationPathName } from "../../utils";
import { useAppSelector } from "../../store/hooks/redux";
import { FlashOnRounded } from "@mui/icons-material";
import { GENERAL_STATUSES } from "../../pages/Documents/utils";


export const useSidebarModel=()=>{

    const drawerWidth = 256;
    const navigate = useNavigate()
    const [view, setView] = useState('list');
    const location = useLocation();
    const {medic} = useAppSelector(state=>state.mainReducer)
    const { isECPFlashLoaded, isDocumentsValid } = useAppSelector(state => state.appReducer)
    const [isSidebarDisabled, setSidebarDisabled] = useState(false)

    useEffect(()=>{
        setSidebarDisabled(!isDocumentsValid || medic.documents_general_status!==GENERAL_STATUSES.ACCEPTED) 
    },[isDocumentsValid])

    useEffect(()=>{
        console.log('sidebar location')
        console.log(location)
        const parsed = parseLocationPathName(location.pathname)
        console.log('parsed')
        console.log(parsed)
        console.log("CHECK")
        parsed[0]==='' ? setView('main') : setView(parsed[0])
    }, [location])

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setView(nextView);
      };


    const { isActive } = useAppSelector(state => state.mainReducer)
    const [modalSwapTab, setModalSwapTab] = useState<boolean>(false)
    const [interceptedTab, setInterceptedTab] = useState<any>()
    const [selectedButton, setSelectedButton] = useState<string>(location.pathname)
    useEffect(()=>{setSelectedButton(location.pathname)},[location.pathname])
    const swapInterceptor = (swapTab: string) => {
        if (isActive && location.pathname === '/meds') {
            setInterceptedTab(swapTab)
            setModalSwapTab(true)
        } else {
            navigate(swapTab)
        }
    }



    return{
        handleChange,
        view,
        isSidebarDisabled,

        swapInterceptor,
        interceptedTab,
        modalSwapTab,
        setModalSwapTab,
        selectedButton
    }
}