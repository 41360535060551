import { Box, CardMedia } from "@mui/material"
import { IdentCard } from "../types"
import serviceDefaultPic from '../../../assets/Pic.png';
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import { IncreasePhotoIcon } from "../assets/IncreasePhotoIcon";

export interface DriverIdentLicenceProps {
    data?: IdentCard | null
}

export const DriverIdentLicence = ({ data }: DriverIdentLicenceProps) => {

    const [toggler, setToggler] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>('')


    useEffect(() => {
        if (data?.license) {

          !!data?.license ?  setImageUrl(data.license) : setImageUrl(serviceDefaultPic) 
        }

    }, [data])

    const curImage = <img src={imageUrl}></img>
    return (
        <Box sx={{
            backgroundColor: 'white',
            fontFamily: 'Roboto',
            //width: '556px',
            width: '100%',
            height: '340px',
            borderRadius: '4px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        }}>

            <h3 style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '26px',
                color: '#3E3E3E'
            }}>
                Водительское удостоверение
            </h3>

            <Box /*sx={{margin: 'auto'}}*/>
                <Box sx={{position: 'relative', display: 'inline-block' }}>
                <CardMedia sx={{ height: '266px', width: '440px', cursor: 'pointer'}} 
                image={!!data?.license ? data.license : serviceDefaultPic} 
                    onClick={()=>{ setToggler(!toggler) }}
                />
                <Box 
                    sx={{
                        position: 'absolute', right: '0px', bottom: '0px', zIndex: '2', pointerEvents: 'none',
                        backgroundColor: 'rgb(0,0,0,0.5)', padding: '4px', borderRadius: '4px', 
                        width: '32px', height: '32px',
                    }}>
                    <IncreasePhotoIcon />
                </Box>
                <FsLightbox
                    toggler={toggler}
                    sources={!!imageUrl ? [curImage] : [serviceDefaultPic]}
                />
                </Box>
            </Box>
        </Box>
    )
}