import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Popper, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { IReportStatus } from "../../Meds/types"
import { getStatusChip } from "../ReportPageModel"


const buttonStyle = {
    boxShadow: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '10px 12px', height: '30px', 
    fontSize: '14px', fontWeight: '500', lineHeight: '1.3',
    "&:hover": { boxShadow: 'none' },
}

const formControlLabelStyle = {
    //margin: '0px',
}

interface FilterProps {
    setFilterRequest?: any
    handleClose?: any
    setMode?: any
}
export const ConclusionFilter = ({ handleClose, setFilterRequest, setMode }: FilterProps) => {

    const [statuses, setStatuses] = useState<any>({ ACCEPTED: false, REJECTED: false, DEFECTED: false })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatuses({...statuses, [event.target.name]: event.target.checked})
    }

    const handleConfirmFilter = () => {
        const resultArray = Object.keys(statuses).filter((key) => statuses[key] === true)
        console.log(resultArray)

        setFilterRequest((prev:any) => ({...prev, medical_report: { status: { in: resultArray } }}))
        setMode(true)
        handleClose()
    }

    const handleResetFilter = () => {
        setFilterRequest((prev:any) => {
            const state = {...prev}
            delete state.medical_report
            return state
        })
        setMode(false)
        handleClose()
    }

    return (
        <Stack sx={{padding: '8px 12px', gap: '8px'}}>

            <FormControl component="fieldset" variant="standard" sx={{margin: '-7px 0px -5px 0px'}}>
                <FormGroup>
                    {Object.keys(statuses).map((key) => (
                        <FormControlLabel key={key}
                        label={getStatusChip(key as IReportStatus)}
                        control={
                            <Checkbox checked={statuses[key]} onChange={handleChange} name={key} />
                        }
                        sx={formControlLabelStyle}
                    />
                    ))}
                </FormGroup>
            </FormControl>

            <Divider sx={{margin: '0px -12px'}}/>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#FFFFFF', color: '#009FDA',
                        border: '2px solid #009FDA',
                        "&:hover": { backgroundColor: '#F0F7FF' }
                    }}
                    onClick={handleResetFilter}
                >
                    Сброс
                </Button>
                <Button
                    sx={{...buttonStyle,
                        backgroundColor: '#009FDA', color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#007DB8' }
                    }}
                    onClick={handleConfirmFilter}
                >
                    Подтвердить
                </Button>
            </Box>
        </Stack>
    )
}