import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
} from "@mui/material";

import { Controller } from 'react-hook-form';
import { activeColor } from "../styles/styles";



interface FormInputProps {
  name: string;
  control: any;
  setValue?: any;
  checkboxValues: any[];

  getSelectedItem?: any
}





export const FormCheckboxes: React.FC<FormInputProps> = ({
  name,
  control,
  setValue,
  checkboxValues,

  getSelectedItem,

}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [isNeedToPrecheck, setIsNeedToPrecheck] = useState(true)
  // we are handling the selection manually here


  const handleSelect = (option: any) => {
    setSelectedItems((prevItems: any) => {
      const isNew = selectedItems.find((element: any) => element.id === option.id);
      if (!isNew) {
        return [...prevItems, option];
      } else {
        const remaining = selectedItems.filter((item: any) => item.id !== option.id);
        return remaining
      }
    })
  }





  const isChecked = (checkbox: any) => {
    return selectedItems.find((element: any) => {
      return element.id === checkbox.id
    })
  }


  useEffect(() => {
    console.log('ZALUPAAAAAA')
    console.log(checkboxValues)
    setSelectedItems((prevItems: any) => {
      const preChecked = [...prevItems, checkboxValues.filter((element: any) => {
        return element.active === true;
      })]
      return preChecked
    })
  }, [ ])


  useEffect(() => {
    if (checkboxValues.length > 0) {
      if (isNeedToPrecheck) {
        console.log(checkboxValues)
        setSelectedItems((prevItems: any) => {
          const preChecked = checkboxValues.filter((element: any) => {
            return element.active === true;
          })
          return preChecked
        })
        setIsNeedToPrecheck(false)
      }
    }

  }, [checkboxValues])


  // we are setting form value manually here
  useEffect(() => {

    console.log('selectedItems')
    console.log(selectedItems)
    setValue(name, selectedItems);

    getSelectedItem(selectedItems)

  }, [name, selectedItems, setValue]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <Stack /*spacing={2}*/ gap={'16px'}>
        {checkboxValues.map((checkbox: any) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={({ field: any }) => {
                    return (
                      <Checkbox
                        sx={{
                          display: 'none'
                        }}
                        value={selectedItems.length !== 0 ? selectedItems.find((element: any) => {
                          return element.id === checkbox.id || element.active == true
                        }) : false}
                        onChange={() => handleSelect(checkbox)}
                      />
                    );
                  }}
                  control={control}
                />
              }
              sx={{
                backgroundColor: selectedItems.find((element: any) => {
                  return element.id === checkbox.id
                }) ? activeColor : 'white',
                color: selectedItems.find((element: any) => {
                  return element.id === checkbox.id
                }) ? 'white' : 'black',
                //height: '62px',
                minHeight: '43px',
                padding: '12px',
                border: '1px solid #D1C8BA',
                borderRadius: '4px',
                marginLeft: 0,
                marginRight: 0,
                userSelect: 'none',
              }}
              label={checkbox.name}
              key={checkbox.id}
            />
          );
        })}
      </Stack>
    </FormControl>
  );
};
