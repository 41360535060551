import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks/redux";
import { setIdentCardInfo, setMedCardInfo } from "../../../MedExamsSlice";
import { DeclineFormInputData, IReportStatus, IdentCardStatus, MedCardStatuses } from "../../../types";
import { useMedDrawerModel } from "../DrawerReportModel";
import { getDriverDeviations } from "../../../../../services/DriverCardService";
import { useModal } from "../../../../../modules/layout/components/modals/useModal";
import { ModalNames } from "../../../../../slices/AppSlice";


export const useDeclineIdentDrawerFormModel = (onClose: any) => {

    const [isTextFieldRequired, setTextFieldrequired] = useState<boolean>(false)
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

    const dispatch = useAppDispatch();
    const { identCard } = useAppSelector(state => state.examReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const [options, setOptions] = useState<[{id?: number, name?: string}]>([{id: 10, name: ''}])

    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [comment, setComment] = useState<string>('');

    const { changeCurrentModal } = useModal()

    useEffect(() => {
        (async () => {
            const response = await getDeviations()
            // response.push({id: 0, name: 'Другое (указать в заключении)'})
            setOptions(response)
        })()
    }, [])

    const getDeviations = async () => {
        try {
            const response = await getDriverDeviations()
            return response.data
        } catch (e) {
            console.log(e)
        }        
    }

    const defaultValues = {
        comment: '',
        checkboxes: [],
    }

    const templateModel = useMedDrawerModel(defaultValues);

    useEffect(()=>{

        if(selectedItems.length === 0 && comment !== '') {
            setDisableSubmit(true)
            setTextFieldrequired(true)
        } else if (selectedItems.find((checkbox: any) => (checkbox.id === 10)) && comment === '') {
            setDisableSubmit(true)
            setTextFieldrequired(true)
        } else if (selectedItems.length === 0 ) {
            setDisableSubmit(true)
            setTextFieldrequired(false)
        } else {
            setDisableSubmit(false)
            setTextFieldrequired(false)
        }
        
    }, [selectedItems, comment])


    const onSubmit = (data: any) => {
        console.log(data)
        const isDataNotEmpty = data.checkboxes && data.checkboxes.length > 0;
        if(isDataNotEmpty){
            console.log('Valid')
            // if (data.checkboxes.find((checkbox: any) => (checkbox.id === 0))) {
            //     data.checkboxes.splice(data.checkboxes.findIndex((checkbox: any) => (checkbox.id === 0)), 1)
            //     console.log('deleted')
            // }
            try{
                console.log(data.checkboxes)
                console.log(data.comment)
                let update: any = {
                    id: identCard !== null ? identCard.id : null,
                    medicId: medic?.id,
                    status: IdentCardStatus.UNCONFIRMED,
                }
                if (data.comment) {
                    update.comment = data.comment
                }
                if (data.checkboxes.length !== 0) {
                    update.medical_report = {
                        deviations: data.checkboxes.map((checkbox:any) => ({id: checkbox.id}))
                    }
                } 

                console.log(update)
                
                templateModel.updateIdentCard(update)
                dispatch(setIdentCardInfo(null))
                onClose()
                changeCurrentModal(ModalNames.SUCCESS)
                //window.alert('Уведомление о браке отправлено')
            } catch(e){
                //window.alert('Ошибка')
                console.log(e)
            }
        } else console.log('Not Valid')
    };

    const submitHandler = templateModel.handleSubmit(onSubmit)

    return{
        control: templateModel.control,
        options,
        submitHandler,
        handleFormValues: templateModel.setValue,
        register: templateModel.register,
        getValues: templateModel.getValues,
        isTextFieldRequired,
        disableSubmit,
        setSelectedItems,
        setComment,
    }
}