import { Box, Button, Card, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, SxProps, Typography } from "@mui/material"
import { MedDataTable } from "./components/MedDataTable"
import { MainDataCard } from "./components/MainDataCard"
import { buttonStyle, styleCard } from "./styles/styles"
import { VideoData } from "./components/VideoData"
import { useEffect, useState } from "react"
import { useMedsPageModel } from "./MedCardPageModel"
import { DefectDrawerForm } from "./modules/Drawers/DefectDrawerForm/DefectForm"
import { DeclineDrawerForm } from "./modules/Drawers/DeclineDrawerForm/DeclineDrawerForm"
import { DriverIdentInfo } from "./components/DriverIdentInfo"
import { DriverIdentLicence } from "./components/DriverIdentLicence"
import { DriverIdentResult } from "./components/DriverIdentResult"
import { DriverIdentVideo } from "./components/DriverIdentVideo"
import { DeclineIdentDrawerForm } from "./modules/Drawers/DeclineIdentDrawerForm/DeclineIdentDrawerForm"
import { CardType } from "./types"
import { ModalNoneCards } from "./components/ModalNoneCards"
import { ModalLoadingCards } from "./components/ModalLoadingCards"
import { TimeProgressBar } from "./components/TimeProgressBar"
import { ModalDocumentProcessing } from "../../modules/layout/components/modals/ModalDocumentProcessing"
import { ModalEDSNotFound } from "../../modules/layout/components/modals/ModalEDSNotFound"


const buttonMainStyle = {

}

const buttonContainer: SxProps = {
    // position:'absolute',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    gap: '24px',
}


// const buttonStyle:SxProps={
//     height:'48px',
//     width:'168px',
//     borderRadius:'4px'
// }


export const MedsPage = () => {

    const model = useMedsPageModel();
    const { card, identCard } = model;
    const tableData = model.tableMedData

    return (
        <Box sx={{
            height: '889px',//minHeight: '100vh',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding:'16px',
        }}>

            { model.isActive && 
              model.cardType !== CardType.LOADING && 
              model.cardType !== CardType.NONE 
              ?
                <Box sx={{ 
                    width: '100%',
                    borderRadius: '4px',
                    backgroundColor: "white", 
                    padding: '16px', position: 'relative', 
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                }}>
                    { model.cardType === CardType.MEDICAL &&
                        <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{
                            !!card &&
                            `${card?.type} осмотр №${card?.id} от ${card?.createdAt ? new Date(card?.createdAt.substring(0, 11)).toLocaleDateString('ru-RU') : '-'}`
                        }</Typography>
                    }
                    { model.cardType === CardType.IDENTIFICATION &&
                        <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{
                            !!identCard &&
                            `Идентификация личности: №${identCard?.id} от ${identCard?.createdAt ? new Date(identCard?.createdAt.substring(0, 11)).toLocaleDateString('ru-RU') : '-'}`
                        }</Typography>
                    }
                    <TimeProgressBar />
                </Box>
                : null
            }

            

            { model.isActive && model.cardType === CardType.MEDICAL &&

                <Box sx={{
                    position: 'relative',
                    //margin: '20px',
                    display: 'flex',
                    gap: '24px',
                    //height: '900px'
                }}>
                    <Stack spacing={'24px'} width={'100%'}>
                        {!!model.driverData
                         ? <MainDataCard data={model.driverData} />
                        : <></>}
                        
    
                        <MedDataTable normsData={model.normTableData} actualData={tableData} />
    
                    </Stack>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '16px',
                            width:'100%',
                            boxShadow: 'none',
                            gap: '24px'
                        }}
                    >
                        <VideoData
                          fullSrc={card?.full_url}
                          temperatureSrc={card?.temperature_measurement_url}
                          pressureSrc={card?.preassure_measurement_url}
                        ></VideoData>
                        <Box sx={buttonContainer}>
                            <Button onClick={ model.toggleDefectDrawer(true) } variant={'contained'} 
                                sx={[buttonStyle, {width: '100%', backgroundColor: '#F0901E', 
                                    '&:hover': {
                                        backgroundColor: '#D0700C',
                                    }
                                }]} 
                            >
                                Брак
                            </Button>
                            <Button onClick={ model.toggleDeclineDrawer(true) } variant={'contained'} 
                                sx={[buttonStyle, {width: '100%', backgroundColor: '#FF4D4F', 
                                    '&:hover': {
                                        backgroundColor: '#DD2B2D',
                                    }
                                }]}
                            >
                                Отклонить
                                </Button>
                            <Button onClick={() => model.approve()} variant={'contained'} 
                                sx={[buttonStyle, {width: '100%', backgroundColor: '#33C341',
                                    '&:hover': {
                                        backgroundColor: '#11A120',
                                    }
                                }]}
                            >
                                Допустить
                            </Button>
                        </Box>
                    </Card>
                    <DefectDrawerForm
                        open={model.isDefectOpen}
                        onOpen={model.toggleDefectDrawer(true)}
                        onClose={model.toggleDefectDrawer(false)}
                    />
                    <DeclineDrawerForm
                        open={model.isDeclineOpen}
                        onOpen={model.toggleDeclineDrawer(true)}
                        onClose={model.toggleDeclineDrawer(false)}
                    />
                </Box>

            }

            { model.isActive && model.cardType === CardType.IDENTIFICATION && 

                <Box sx={{
                    position: 'relative',
                    width:'100%',
                    //margin: '20px',
                    display: 'flex',
                    gap: '24px',
                    height: '900px',
                }}>
                    <Stack spacing={'24px'} width={'100%'}>
                        <DriverIdentInfo driver={model.identCard?.driver} />
                        <DriverIdentVideo data={model.identCard} />
                    </Stack>

                    <Stack spacing={'24px'} width={'100%'}>
                        <DriverIdentLicence data={model.identCard} />
                        <DriverIdentResult
                            approve={model.approveIdent} 
                            decline={model.toggleDeclineIdentDrawer(true)}
                        />
                    </Stack>

                    <DeclineIdentDrawerForm 
                        open={model.isDeclineIdentOpen}
                        onOpen={model.toggleDeclineIdentDrawer(true)}
                        onClose={model.toggleDeclineIdentDrawer(false)}
                    />
                </Box>
            }

            { model.isActive && model.cardType === CardType.LOADING && 
                <ModalLoadingCards />
            }

            { model.isActive && model.cardType === CardType.NONE && 
                <ModalNoneCards />
            }

        </Box>
    )


}