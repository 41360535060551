import { Box, Button, Divider, Drawer, IconButton, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { navBarStyle, navBtnStyle, titleMargin } from "../../modules/layout/style";
import { useLocation, useNavigate } from "react-router-dom";
import gearIcon from '../../assets/inline-menu-item/icon-wrapper/14px.png';
import TetronLogo from '../../assets/SidebarLogo.png'
import { useEffect, useState } from "react";
import { parseLocationPathName } from "../../utils";
import { useSidebarModel } from "./SidebarModel";
import { GearIcon } from "./assets/GearIcon";
import { MedExamIcon } from "./assets/MedExamIcon";
import { ReportsIcon } from "./assets/ReportsIcon";
import { EmployeesIcon } from "./assets/EmployeesIcon";
import { ScheduleIcon } from "./assets/ScheduleIcon";
import { MainIcon } from "./assets/MainIcon";
import { DocumentsIcon } from "./assets/DocumentsIcon";
import { useAppSelector } from "../../store/hooks/redux";
import { ModalSwapTab } from "../Timer/components/ModalSwapTab";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { SidebarRollIcon } from "./assets/SidebarRollIcon";
import { LogoIcon } from "./assets/TetronLogo/LogoIcon";
import { NameIcon } from "./assets/TetronLogo/NameIcon";
import { TitleIcon } from "./assets/TetronLogo/TitleIcon";
import { Visibility } from "@mui/icons-material";

export const Sidebar = (props:{ setWidth?: any }) => {

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)

  const drawerWidth = 256;
  const navigate = useNavigate()
  const sidebarModel = useSidebarModel();
  const { isDocumentsValid } = useAppSelector(state => state.appReducer)
  const drawer = (
    <div style={{
      marginTop: '60px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      {/* <img src={TetronLogo} alt="" width={'140px'} style={{ margin: 'auto' }} /> */}

      <Box sx={{ position: 'absolute', top: '30px', transition: 'all 0.3s ease-out'}}>
        <Box sx={{position: 'relative', width: '100%', height: '100%'}}>
          <LogoIcon style={{scale: sidebarOpen ? '1' : '0.5', transition: 'all 0.3s ease-out',
              position: 'absolute', top: sidebarOpen?1:-8, right: sidebarOpen?1:-42}}/>
          <NameIcon style={{scale: sidebarOpen ? '1' : '0.5', transition: 'all 0.3s ease-out',
            position: 'absolute', top: sidebarOpen?0:18, right: sidebarOpen?-85:-42}}/>
          <TitleIcon style={{visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?'1':'0', transition: 'all 0.3s ease-out',
            position: 'absolute', top: 21, left: -5
          }}/>
        </Box>
      </Box>

      <Stack

        sx={{
          color: '#AFAFAF',
          marginTop: '73px',
          width: '100%',
        }}
        spacing={2}>
        <ToggleButtonGroup
          sx={navBarStyle}
          fullWidth
          orientation="vertical"
          value={sidebarModel.view}
          exclusive
          onChange={sidebarModel.handleChange}
        >
          <ToggleButton onClick={() => { sidebarModel.swapInterceptor('/main') }} value="main"
            disabled={sidebarModel.isSidebarDisabled}
            sx={{
              ...navBtnStyle,
              // display: sidebarModel.isSidebarDisabled ? 'none' : 'default'
            }}
            selected={sidebarModel.selectedButton === '/main'}
          >
            <MainIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Главная</p>
          </ToggleButton>
          <ToggleButton onClick={() => { navigate('/meds') }} value="meds"
            disabled={sidebarModel.isSidebarDisabled}
            sx={{
              ...navBtnStyle,
              // display: sidebarModel.isSidebarDisabled ? 'none' : 'default'
            }}
            selected={sidebarModel.selectedButton === '/meds'}
          >
            <MedExamIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Медосмотры</p>
          </ToggleButton>
          <ToggleButton onClick={() => { sidebarModel.swapInterceptor('/reports') }} value="reports"
            disabled={sidebarModel.isSidebarDisabled}
            sx={{
              ...navBtnStyle,
              // display: sidebarModel.isSidebarDisabled ? 'none' : 'default'
            }}
            selected={sidebarModel.selectedButton === '/reports'}
          >
            <ReportsIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Отчеты</p>
          </ToggleButton>
          <ToggleButton onClick={() => { sidebarModel.swapInterceptor('/employees') }} value="employees"
            disabled={sidebarModel.isSidebarDisabled}
            sx={{
              ...navBtnStyle,
              // display: sidebarModel.isSidebarDisabled ? 'none' : 'default'
            }}
            selected={sidebarModel.selectedButton === '/employees'}
          >
            <EmployeesIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Мои организации</p>
          </ToggleButton>
          <ToggleButton onClick={() => { sidebarModel.swapInterceptor('/shifts') }} value="shifts"
            disabled={sidebarModel.isSidebarDisabled}
            sx={{
              ...navBtnStyle,
              // display: sidebarModel.isSidebarDisabled ? 'none' : 'default'
            }}
            selected={sidebarModel.selectedButton === '/shifts'}
          >
            <ScheduleIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>График смен</p>
          </ToggleButton>
          <ToggleButton onClick={() => { sidebarModel.swapInterceptor('/documents') }} value="documents"
            sx={navBtnStyle}
            selected={sidebarModel.selectedButton === '/documents'}
          >
            <DocumentsIcon />
            <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Мои Документы</p>
          </ToggleButton>
          <ToggleButton onClick={() => {
              localStorage.clear()
              navigate('/login')
           }} value=""
            sx={{...navBtnStyle}}
           
          >
                <ExitToAppIcon />
        <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Выйти</p>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {/* <Button
        onClick={() => {
          localStorage.clear()
          navigate('/login')
        }}
        sx={{ ...navBtnStyle,
           marginTop: 2,
           paddingLeft:'10px',
           alignSelf: 'flex-start',
           width:'100%'
           }}
      >
        <ExitToAppIcon />
        <p style={{...titleMargin, visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0}}>Выйти</p>
      </Button> */}
      <ModalSwapTab open={sidebarModel.modalSwapTab} setOpen={sidebarModel.setModalSwapTab} swapTab={sidebarModel.interceptedTab} />
    </div>
  );

  return <Drawer
    variant="permanent"
    sx={{
      display: { xs: 'none', sm: 'block' },
      '& .MuiDrawer-paper': { 
        boxSizing: 'border-box', width: sidebarOpen ? drawerWidth : '56px', backgroundColor: '#2A2A2A',
        transition: 'all 0.3s ease-out', whiteSpace: 'nowrap', overflow: 'hidden'
    },
    }}
    open
  >
    {drawer}
    <IconButton 
      onClick={() => setSidebarOpen((prev) => {props.setWidth(prev ? 56 : drawerWidth) ; return !prev})}
      sx={{ 
        position: 'absolute', bottom: '20px', left: '20px', 
      }}
    >
      <SidebarRollIcon style={{ position: 'absolute', transition:'all 0.3s ease-out',
        visibility:sidebarOpen?'visible':'hidden', opacity:sidebarOpen?1:0,
      }}/>
      <SidebarRollIcon style={{ position: 'absolute', transform:'scaleX(-1)', transition:'opacity 0.3s ease-out, visibility 0.3s ease-out',
        visibility:sidebarOpen?'hidden':'visible', opacity:sidebarOpen?0:1, 
      }}/>
    </IconButton>
  </Drawer>
}
