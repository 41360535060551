import React, { useEffect, useState } from "react";
import { Box, Button, Card, Checkbox, Chip, FormControl, IconButton, Input, InputAdornment, OutlinedInput, Paper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material"
import { IMedCard, IReportStatus, MedCardStatuses } from "../Meds/types";
import { ReportCard, downloadFile } from "./drawers/ReportCard";
import { useParams } from "react-router-dom";
import { SearchIcon } from "./assets/SearchIcon";
import { excelArgs, getExcelReportTableRequest } from "../../services/fileService";
import { buttonStyle } from "../Login/login-styles";
import { activeColor, textFieldOutlineMultilineStyle } from "../Meds/styles/styles";
import SingleInputDateRangePicker from "../../modules/layout/components/SingleDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarIcon } from "@mui/x-date-pickers";
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { CaretUpIcon } from "./assets/CaretUpIcon";
import { CaretDownIcon } from "./assets/CaretDownIcon";
import { UnionIcon } from "./assets/UnionIcon";
import { Padding } from "@mui/icons-material";
import { column } from "./ReportPageModel";



const tableHeadRow = {
  backgroundColor: '#FAFAFA',
  position: 'sticky',
  top: '0px',
  zIndex: '2',

  '& .MuiTableCell-root': {
    borderColor: '#EEEEEE',
    position: 'relative',

    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#222222',
    
    '&::after': {
      padding: '0px',
      content: '""',
      position: 'absolute',
      height: '22px',
      width: '1px',
      backgroundColor: '#EEEEEE',
      marginTop: '-20px',
      right: 0
    },
    "&:last-child": {
      '&::after': {
        display: 'none',
      },
    },
  },

  '& .MuiIconButton-root': {
    padding: '0px',
    margin: '-10px -5px -10px -5px',
    width: '20px',
    height: '40px',
    borderRadius: '6px',
    alignContent: 'center'
  }
}
const tableHeadCellData = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  textWrap: 'nowrap',
}

const tableBodyRow = {
  '& .MuiTableCell-body': {
    backgroundColor: '#FFFFFF',
    borderColor: '#EEEEEE',
    padding: '16px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#222222',
    // "&:last-child": {
    //   //borderTop: 'none',
    //   borderBottom: 'none',
    // },
    overflowX: 'hidden',
    //whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px', // ???
  },
  '&:hover': {
    backgroundColor: "#E3F2FF !important"
  },
  '&.Mui-selected': {
    backgroundColor: "#E3F2FF !important"
  }
}

const TableHeadCell = ({ id, name, sort, filter, visible, sortMode }: column) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  const [clickCount, setClickCount] = useState<number>(0)
  const handleSort = () => { setClickCount((prev)=>{sort(sortMode===''?1:prev+1); return sortMode===''?1:prev+1});}

  useEffect(()=>{console.log(id)},[sortMode])
  
  const [filterMode, setFilterMode] = useState<boolean>(false)

  return visible ?
    <TableCell align="left">
      <Box sx={tableHeadCellData}>
        <Box>{name}</Box>
        <Stack flexDirection={'row'} gap={'inherit'}>
        { sort &&
          <IconButton onClick={handleSort}>
            <Stack spacing={'-3px'}>
              <CaretUpIcon fill={sortMode === 'asc' ? '#1890FF' : '#9B9B9B'}/>
              <CaretDownIcon fill={sortMode === 'desc' ? '#1890FF' : '#9B9B9B'}/>
            </Stack>
          </IconButton>
        }
        { filter && 
          <IconButton 
            onClick={handleOpen}>
            <UnionIcon fill={filterMode ? '#1890FF' : '#9B9B9B'}/>
          </IconButton>
        }
        </Stack>        
      </Box>
      { filter &&
        <Popover 
          sx={{
            '& .MuiPaper-root': {
              marginTop: '17px',
              boxShadow: '0 5px 5px 7px rgba(0,0,0,0.05)',
              //maxWidth: '320px',
            }
          }}
          open={Boolean(anchorEl)} 
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          {filter && filter(handleClose, setFilterMode)}
        </Popover>
      }
    </TableCell>
  : null
}

const TableBodyCell = ({ id, value, visible }: column) => {
  return visible ?
    <TableCell align="left">
      {value}
    </TableCell>
  : null
}



interface ITableProps {
  data: IMedCard[] | null
  columns: column[]
  setFilterRequest: any
}

export const CustomTable = ({ data, columns, setFilterRequest }: ITableProps) => {
  const [rows, setRows] = useState(data);
  const [searched, setSearched] = useState("");
  const [pickedReportData, setPickedReportData] = useState<IMedCard | null>(null)


  useEffect(() => {
    setRows(data);
  }, [data])

  const requestSearch = (searchedVal: any) => {
    if (data !== null) {
      const filteredRows = data.filter((row: IMedCard) => {
        if (!!row.driver?.lastname) return row.driver?.lastname.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };


  const [typingTimer, setTypingTimer] = useState<any>()
  const typingListener = (inputValue: any, typingPause: number, callback: () => void) => {
    clearTimeout(typingTimer); setTypingTimer(setTimeout(callback, typingPause))
  }
  const searchRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    typingListener(event.target.value, 800, () => {
      if (event.target.value !== '') {
        setFilterRequest((prev:any)=>({...prev, search: event.target.value }))
      } else {
        setFilterRequest((prev:any) => delete prev.search)
      }
    })
  }

  

  return (
    <Stack
      sx={{
        gap: '16px',

        '*::-webkit-scrollbar': {
          width: '12px',
          height: '12px',
        },
        '*::-webkit-scrollbar-track:vertical': {
          backgroundColor: '#EEEEEE',
          borderBottom: '2px solid white'
        },
        '*::-webkit-scrollbar-track:horizontal': {
          backgroundColor: '#EEEEEE',
          borderRight: '2px solid white',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#BDBDBD',
          border: '2px solid transparent',
          //borderRadius: '10px / 4px',
          borderRadius: '5px/5px',
          backgroundClip: 'content-box',
        },
        '*::-webkit-scrollbar-corner': {
          backgroundColor: '#EEEEEE',
          borderRadius: '2px',
        },

      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >

      <FormControl
        sx={{
          '& .MuiInputBase-input': {
            WebkitBoxShadow: '0 0 0 1000px white inset'
          },
          '& .MuiOutlinedInput-root': {
            maxWidth: '436px',
            minWidth: '436px',
            maxHeight: '36px',
            minHeight: '36px',
            padding: '9px 0px 9px 12px',
            '& input::placeholder': {
              color: '#8E8E8E',
              opacity: 1,
            },
            '& fieldset': {
              borderColor: '#D9D9D9',
            },
            '& .MuiInputAdornment-root': {
              pointerEvents: 'none',
            },

            '&.MuiOutlinedInput-root:hover': {
              opacity: 1,
              '& fieldset': {
                border: '2px solid #A5E5FD',
              },
            },
            '&.MuiOutlinedInput-root.Mui-focused': {
              opacity: 1,
              '& fieldset': {
                border: '2px solid #009FDA',
              },
            },
          },
        }}
      >
        <OutlinedInput
          placeholder="Поиск"
          startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
          inputProps={{form: {autoComplete: 'off'}}}
          autoComplete="off"
          onChange={searchRequest}
        />
      </FormControl>

      </Box>
      

      <TableContainer
        sx={{
          // maxHeight: '630px',
          width: '100%',
          height: '604px',
          position: 'relative',
          borderRadius: '4px 0px 0px 0px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <Table sx={{ position: 'relative' }}>
          <TableHead>
            <TableRow sx={{ ...tableHeadRow }}>
              {columns.map((col) => (
                <TableHeadCell id={col.id} name={col.name} sort={col.sort} filter={col.filter} visible={col.visible} sortMode={col.sortMode}/>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows ? rows.map((row: IMedCard) => (
              <TableRow key={row.id} style={{ cursor: 'pointer' }} onClick={() => { setPickedReportData(row) }}
                sx={{ ...tableBodyRow }}
                hover={true} 
                selected={pickedReportData?.id === row.id}
              >
                {columns.map((col) => (
                  <TableBodyCell id={col.id} value={col.value(row)} visible={col.visible} />
                ))}
              </TableRow>
            )) : <></>}

          </TableBody>
        </Table>
      </TableContainer >




      <ReportCard open={!!pickedReportData} onOpen={() => { }} card={pickedReportData} onClose={() => setPickedReportData(null)} />
    </Stack >
  );
};

