import { Box, CardMedia } from "@mui/material"
import { IdentCard } from "../types"
import serviceDefaultPic from '../../../assets/Pic.png';


export interface DriverIdentVideoProps {
    data?: IdentCard | null
}

export const DriverIdentVideo = ({ data }: DriverIdentVideoProps) => {

    return (
        <Box sx={{
            backgroundColor: 'white',
            fontFamily: 'Roboto',
            //width: '556px',
            width: '100%',
            height: '360px',
            borderRadius: '4px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        }}>

            <h3 style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '26px',
                color: '#3E3E3E'
            }}>
                Процесс идентификации
            </h3>

            <Box>
                <video style={{height: '275px', borderRadius: '4px'}} autoPlay muted controls src={!!data?.video ? data.video : ''}></video>
                {/* <CardMedia sx={{ height: '486px', width: '275px'}} image={!!data?.video ? data.video : serviceDefaultPic} /> */}
            </Box>
        </Box>
    )
}