import { Box, Button, Drawer, IconButton, Paper, Stack, SwipeableDrawer, TextField, Typography } from "@mui/material"
import { ReactComponent as CloseIcon } from "../../../pages/Meds/modules/Drawers/assets/Close.svg";
import { IDeviation, IMedCard, INorm, INormTable, IReportStatus, MedCardStatuses, NormCodes } from "../../Meds/types"
import { activeColor, drawerBoxStyle, textFieldOutlineMultilineStyle } from "../../Meds/styles/styles"
import { buttonStyle } from "../../Login/login-styles"
import { MedDataTable } from "../../Meds/components/MedDataTable"
import { getTableDataFromCard } from "../../Meds/MedCardPageModel"
import { getNormsRequest } from "../../../services/DriverCardService"
import { useEffect, useState } from "react"
import { MainDataCard } from "../../Meds/components/MainDataCard"
import classes from './report.module.css'
import { DriverIdentInfo } from "../../Meds/components/DriverIdentInfo"
import { $file } from "../../../api"
import { getStatusChip } from "../ReportPageModel"
import { DownloadIcon } from "../assets/DownloadIcon"



export interface ReportCardProps {
    open: boolean,
    onClose: any,
    onOpen: any,
    card: IMedCard | null
}


export const downloadFile = (url: string, filename: string) => {

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export const ReportCard = ({ open, onClose, onOpen, card }: ReportCardProps) => {

    const [norms, setNorms] = useState<INorm[] | undefined>()


    const getNorms = async () => {
        try {
            const response = await getNormsRequest();
            console.log('norms')
            console.log(response)
            setNorms(response.data)
        } catch (e) {

        }
    }

    useEffect(() => {
        getNorms()
    }, [])



    const normTableData: INormTable | null = !!norms && norms !== null ? {
        systolicPressure: norms.find((element: INorm) => {
            return element.code === NormCodes.SYSTOLIC
        }),
        diastolicPressure: norms.find((element: any) => {
            return element.code === NormCodes.DIASTOLIC
        }),
        pulse: norms.find((element: any) => {
            return element.code === NormCodes.PULSE
        }),
        temperature: norms.find((element: any) => {
            return element.code === NormCodes.TEMPERATURE
        }),
        alcohol: norms.find((element: any) => {
            return element.code === NormCodes.ALCOHOL
        }),
    } : null




    const download = async (url?: string) => {
        console.log(url)
        if (!!url) {
            const fileUrl = await $file.get(url);
            const localUrl = URL.createObjectURL(fileUrl.data)
            downloadFile(localUrl, 'documents.zip')
        }
    }



    console.log(card)

    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            sx={{
                //height: 'fit-content',

                '*::-webkit-scrollbar': {
                    width: '24px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: '#EEEEEE',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: '#8E8E8E',
                    border: '6px solid transparent',
                    //borderTopWidth: '0px',
                    //borderBottomWidth: '0px',
                    // borderRadius: '10px / 4px',
                    borderRadius: '10px / 10px',
                    backgroundClip: 'content-box',
                },
            }}
        >
            <Box sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                padding: '24px', backgroundColor: '#EEEEEE'
            }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '500', lineHeight: '23px', color: '#3E3E3E' }} component={'h1'}>Карточка осмотра</Typography>
                <IconButton onClick={onClose} color="secondary" aria-label="add an alarm"
                    sx={{'&.MuiButtonBase-root': {borderRadius: '4px'}, '&:hover': {backgroundColor: '#FFFFFF'}}}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    ...drawerBoxStyle,
                    width: '748px',
                    //width: '100%',

                    overflowX: 'hidden',
                    paddingLeft: '24px',
                }}
                role="presentation"
            >
                <Stack sx={{ gap: '16px' }}>
                    <DriverIdentInfo
                        driver={card?.driver}
                        statusChip={getStatusChip(card?.medical_report?.status as IReportStatus)}
                        coverBgColor={'#D9D9D9'} coverPadding={'12px'} textSize={'16px'} textGap={'12px'} showPhotoTitle={false}
                    />
                    <MedDataTable
                        normsData={normTableData}
                        actualData={getTableDataFromCard(card)}
                        coverBgColor={'#D9D9D9'} coverPadding={'12px'} textSize={'16px'} textGap={'12px'}
                    />
                    {card?.medical_report?.status === IReportStatus.REJECTED || card?.medical_report?.status === IReportStatus.DEFECTED
                        ? <Stack
                            sx={{borderRadius: '4px 4px 4px 4px', boxShadow: 'none', backgroundColor: '#D9D9D9', padding: '12px', gap: '12px'}}
                        >
                            <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#3E3E3E' }}>Причины недопуска</Typography>
                            <Stack gap={1}>
                                {card?.medical_report?.deviations?.map((element: any) => {
                                    return <Box sx={{ padding: '12px', backgroundColor: activeColor, borderRadius: '4px' }}>
                                        <Typography color={'white'}>
                                            {element.name}
                                        </Typography>
                                    </Box>
                                })}
                                {card?.medical_report?.defects?.map((element: any) => {
                                    return <Box sx={{ padding: '12px', backgroundColor: activeColor, borderRadius: '4px' }}>
                                        <Typography color={'white'}>
                                            {element.name}
                                        </Typography>
                                    </Box>
                                })}
                                {!!card?.medical_report?.comment && <Box>
                                    <Box >
                                        {/* <Typography>
                                            Комментарий
                                        </Typography>
                                        <Typography>
                                            {card?.medical_report?.comment}
                                        </Typography> */}

                                        <TextField
                                            sx={{...textFieldOutlineMultilineStyle, userSelect: 'none'}}
                                            multiline
                                            rows={4}
                                            size="small"
                                            value={card?.medical_report?.comment}
                                            fullWidth
                                            label={'Комментарий медика'}
                                            variant="outlined"

                                            disabled
                                            InputLabelProps={{shrink: false}}
                                            InputProps={{readOnly: true}}
                                        />
                                        
                                    </Box>
                                </Box>}
                            </Stack>

                        </Stack>
                        : <></>
                    }

                    <Stack sx={{ padding: '12px', gap: '24px', borderRadius: '4px', backgroundColor: '#D9D9D9', width: '100%' }}>
                        <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#3E3E3E' }}>
                            Видеоматериал
                        </Typography>

                        <Stack sx={{ gap: '8px' }}>
                            <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#2A2A2A' }}>
                                Измерение давления и пульса
                            </Typography>
                            <video style={{ borderRadius: '4px', }}
                                src={card?.preassure_measurement_url ? card?.preassure_measurement_url : undefined}
                                autoPlay muted controls
                            ></video>
                        </Stack>

                        <Stack sx={{ gap: '8px' }}>
                            <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#2A2A2A' }}>
                                Измерение температуры и паров этанола
                            </Typography>
                            <video style={{ borderRadius: '4px', }}
                                src={card?.temperature_measurement_url ? card?.temperature_measurement_url : undefined}
                                autoPlay muted controls
                            ></video>
                        </Stack>

                        <Stack sx={{ gap: '8px' }}>
                            <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#2A2A2A' }}>
                                Полное видео осмотра
                            </Typography>
                            <video style={{ borderRadius: '4px', }}
                                src={card?.full_url ? card?.full_url : undefined}
                                autoPlay muted controls
                            ></video>
                        </Stack>
                    </Stack>

                </Stack>



            </Box>

            <Box
                sx={{
                    backgroundColor: '#EEEEEE',
                    position: 'sticky',
                    bottom: '0px',
                    padding: '24px',

                    display: 'flex',
                    gap: '24px',
                    justifyContent: 'space-around'
                }}
            >
                <Button sx={{ ...buttonStyle, width: '330px', backgroundColor: activeColor, /*marginTop: 2*/ 
                    '&:hover': {
                            backgroundColor: '#007DB8',
                            boxShadow: 'none',
                        }
                }} startIcon={<DownloadIcon/>}
                    fullWidth onClick={() => { download(card?.archive_url) }} variant="contained">Протокол осмотра</Button>
                <Button
                    disabled={card?.medical_report?.status === IReportStatus.ACCEPTED
                        || card?.medical_report?.status === IReportStatus.DEFECTED}
                    sx={{ ...buttonStyle, width: '330px', backgroundColor: activeColor, /*marginTop: 2*/ 
                        '&:hover': {
                            backgroundColor: '#007DB8',
                            boxShadow: 'none',
                        }
                    }} startIcon={<DownloadIcon/>}
                    onClick={() => { download(card?.archive_url2) }} variant="contained">Направление к врачу</Button>
            </Box>




            {/* </Box> */}
        </Drawer >
    )
}