import { Box, Button, Modal, Stack, Typography } from "@mui/material"
import { IModalProps } from "./props"
import { activeColor } from "../../../../pages/Meds/styles/styles"
import { useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux"
import { useEffect, useState } from "react"
import { getUserCertificates } from "crypto-pro"
import { Certificate } from "crypto"
import { ControlledRadioGroup } from "../../../../components/ControlledRadioInput/ControlledRadioGroup"
import { setConclusion } from "../../../../pages/Meds/MedCardPageModel"
import { useModal } from "./useModal"
import { ModalNames } from "../../../../slices/AppSlice"
import { ICertificate, getAvailableCertificates } from "../../../../services/cryptoService"
import { setBase64File, setMedCardInfo,  } from "../../../../pages/Meds/MedExamsSlice"


export const ConclusionModal = ({ open, handleClose }: IModalProps) => {

    const { control, handleSubmit, getValues } = useForm()

    const { changeCurrentModal } = useModal()

    const { card, medicalReport } = useAppSelector(state => state.examReducer)
    const { medic } = useAppSelector(state => state.mainReducer)
    const dispatch = useAppDispatch()
    const [certificates, setCertificates] = useState<any>([])
    const [isCertificatesLoading, setLoadingCertificates] = useState(false)

    const onSubmit = async (data: any) => {
        console.log(data)
        const currentCertificate = certificates.find((item: any) => item.thumbprint === data.thumbprint)
        console.log('текущий сертификат')
        console.log(currentCertificate)
        if (currentCertificate && medicalReport && medic.id) {
            changeCurrentModal(ModalNames.ECP_SIGNING)
            const thumbprint = currentCertificate.thumbprint;
            const activeFrom = currentCertificate.validFrom;
            const activeTo = currentCertificate.validTo;
            const ownerInfo = await currentCertificate?.getOwnerInfo();
            const certificateObj: ICertificate = {
                value: thumbprint,
                owner: !!ownerInfo ? `${ownerInfo[0].description} ${ownerInfo[1].description}` : undefined,
                active_from: activeFrom,
                active_to: activeTo,
            }

            const result = await setConclusion(card, medicalReport, medic.id, certificateObj)
            dispatch(setMedCardInfo(null))
            dispatch(setBase64File(undefined))
            !!result ? changeCurrentModal(ModalNames.SUCCESS)
                : changeCurrentModal(ModalNames.ECP_ERROR)
        }

    }

    useEffect(() => {
        (async () => {
            setLoadingCertificates((state) => state = true)
            const certificates = await getAvailableCertificates();
            setLoadingCertificates((state) => state = false)
            console.log('Загруженные сертификаты')
            console.log(certificates)
            if (!!certificates) {
                setCertificates((state: any) => state = certificates)
                console.log(certificates)
            } 

        })()
    }, [])

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'background.paper',

                    width: '526px',
                    //maxHeight: '320px',
                    borderRadius: '4px',
                    border: 'none',
                    padding: '24px',

                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',

                    '& h2': {
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '28px',
                        color: '#3E3E3E',
                        //whiteSpace: 'nowrap',
                    },

                    // '& p': {
                    //   fontSize: '16px',
                    //   fontWeight: '400',
                    //   color: '#3E3E3E',
                    //   lineHeight: '28px',
                    // },
                }}
            >
                {isCertificatesLoading
                    ? <Typography>Идет загрузка сертификатов...</Typography>
                    : <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',

                            gap: '12px',
                        }}
                    >
                        <h2>
                            Выберите сертификат ЭЦП для подписания заключения
                        </h2>
                        {certificates.length > 0
                            ? <Stack spacing={2}>
                                <ControlledRadioGroup name='thumbprint' control={control} options={certificates.map((certificate: any) => {
                                    return {
                                        label: certificate.name,
                                        value: certificate.thumbprint
                                    }
                                })} />
                                
                                <Button
                                    onClick={
                                        handleSubmit(onSubmit)
                                        // handleClose()
                                    }
                                    disabled = {!getValues('thumbprint')}
                                    sx={{
                                        alignSelf: 'center',
                                        width: '227px',
                                        height: '36px',
                                        backgroundColor: activeColor,
                                        padding: '10px 12px',
                                        borderRadius: '4px',
                                        color: '#FFFFFF',
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '16px',
                                        whiteSpace: 'nowrap',
                                        '&:hover': {
                                            backgroundColor: '#DD2D2F',
                                        }
                                    }}
                                >
                                    Подписать
                                </Button>
                            </Stack>

                            : <Typography>Сертификаты не найдены</Typography>}

                    </Box>
                }

            </Box>
        </Modal>
    )
}