import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IMedicalReport } from "../pages/Meds/types";



export enum ModalNames{
    ECP = 'ECP',
    NOT_ADDED_DOCUMENTS = 'DOCUMENT_CHECK',
    EXPIRES_DOCUMENTS ='EXPIRES_CHECK',
    EXPIRED_DOCUMENTS ='EXPIRED_CHECK',
    ERROR_DOCUMENTS = 'ERROR_DOCUMENTS',
    CHECKING_DOCUMENTS = 'CHECKING_DOCUMENTS',
    ECP_SIGNING = 'ECP_SIGNING',
    ECP_ERROR = 'ECP_ERROR',
    CHECKING_ECP = 'CHECKING_ECP',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCCESS',
    SUCCESSIDENT = 'SUCCESSIDENT',
    CERTIFICATES = 'CERTIFICATES',

}
 

export type ModalType = null | ModalNames


export type ModalQueueType = [] | ModalNames[]

export interface IState{
    currentModal:ModalType
    isECPFlashLoaded:boolean | null,
    modalQueue:ModalQueueType, 
    isDocumentsValid:boolean,
    currentCertificate?:any,
    medicalReport?: IMedicalReport
}


const initialState: IState = {
    currentModal: null,
    isECPFlashLoaded:null,
    modalQueue:[],
    isDocumentsValid:false,
    currentCertificate:undefined,
};


export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setModal(state, action: PayloadAction<ModalType>){
            state.currentModal = action.payload
        },
        setECPFlashLoaded(state, action: PayloadAction<boolean>){
            state.isECPFlashLoaded = action.payload
        },
        setDocumentsValid(state, action: PayloadAction<boolean>){
            state.isDocumentsValid = action.payload && !!state.isECPFlashLoaded
        },
        setModalQueue(state, action: PayloadAction<ModalNames[] | []>){
            state.modalQueue = action.payload
        },
        setCurrentCertificate(state, action: PayloadAction<any>){
            state.currentCertificate = action.payload
        },
    },
})


export const {
    setModal,
    setECPFlashLoaded,
    setModalQueue,
    setDocumentsValid,
    setCurrentCertificate
} = appSlice.actions

export default appSlice.reducer